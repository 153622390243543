import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import screenshot from "../img/amp-technologies.jpg"
import SEO from "../components/seo"

class AMP extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="AMP Technologies" />
        <PageHeader title="AMP Technologies" img={screenshot} />
      </Layout>
    )
  }
}

export default AMP
